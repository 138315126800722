body{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#vRoot{
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
}


input {
  width: 80%;
  height: 50px;
  padding: 10px;
  font-size: 15px;
  background: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.legacy_outline:focus {
  background-color: lightgray;
}

.hidden {
  display: none;
}

.inputsContainer{
  overflow: scroll;
  max-height: 100vh;
  flex-grow: 1;
  flex-shrink: 1;
}

.keyboardContainer {
  max-height: 50vh;
  height: 65vw;
  width: 100vw;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 65vw;
}

.simple-keyboard {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: rgba(226, 226, 226, 0.7);
  border-radius: 0!important;
}

.hg-rows{
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hg-row{
  flex-grow: 1;
}



[data-skuid="default-r0c0"]{
  width: 70vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

[data-skuid="default-r0c0"] div{
  flex-grow: 0!important;
  width: 32.3%!important;
  height: 24% !important;
  flex-grow: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

[data-skuid="default-r0c1"]{
  width: 20%;
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

[ data-skbtn="{bksp}"]{
  width: 100%!important;
  height: 74.8% !important;
  flex-grow: 0 !important;
  padding: 0 !important;
  margin-right: 0px;
  margin-bottom: 0px;
}

[ data-skbtn="{close}"]{
  width: 100%!important;
  height: 24% !important;;
  flex-grow: 0 !important;
  padding: 0 !important;
  margin:  0 !important;
}
